<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="close"
  >
    <v-card
      class="px-6 py-8"
    >
      <v-toolbar
        dark
        :color="options.color"
        dense
        flat
        class="align-center justify-space-between"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.selectProfiles') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="close"
        />
      </v-toolbar>

      <v-card-text
        class="pa-4"
      >
        <div
          class="mb-2 mt-6"
          style="max-height: 280px; overflow-y: auto;"
        >
          <v-treeview
            v-if="superProfilesList.length > 0"
            class="relatedProfilesTreeview"
            :items="superProfilesList"
            item-text="name"
            multiple-active
            activatable
            selected-color="primary"
            :active-class="
              selectedProfiles.length > 0 ? 'v-treeview-node--active' : 'textBlack--text'
            "
            :active="selectedProfiles"
            @update:active="selectProfile"
          >
            <template
              v-slot:label="{ item }"
            >
              <person-preview-line
                :person-id="item.id"
                :is-super-profile="true"
              />
            </template>
          </v-treeview>

          <div
            v-else
            class="name-text mx-4"
          >
            {{ $t('deconve.noResult') }}
          </div>
        </div>
      </v-card-text>

      <v-card-text
        v-if="selectedProfiles.length > 0"
      >
        <div
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutral--text"
        >
          {{ $t('deconve.selectedProfiles') }}
        </div>
        <div
          class="d-flex flex-wrap"
        >
          <v-chip
            v-for="(selectedPersonId, index) in selectedProfiles"
            :key="selectedPersonId"
            class="mx-2 my-1"
            close
            close-icon="mdi-close"
            label
            outlined
            @click:close="removeSelectedPerson(index)"
          >
            <div class="chip-text">
              {{ getPersonNameById(selectedPersonId) }}
            </div>
          </v-chip>
        </div>
      </v-card-text>

      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          @click="close"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapGetters } from 'vuex';
import SquareButton from './SquareButton.vue';
import PersonPreviewLine from './PersonPreviewLine.vue';

export default {
  name: 'SimilarSuperProfilesDialog',
  components: {
    SquareButton,
    PersonPreviewLine,
  },
  data() {
    return {
      dialog: false,
      superProfilesList: [],
      selectedProfiles: [],
      options: {
        color: 'white',
        width: 480,
        height: 150,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSuperProfile: 'faceid/getSuperProfile',
    }),
    getPersonNameById() {
      return (superProfileId) => {
        const { name } = this.getSuperProfile(superProfileId);

        return name;
      };
    },
  },
  methods: {
    open(similarSuperProfiles, selectedSuperProfiles) {
      this.selectedProfiles = selectedSuperProfiles;
      this.superProfilesList = similarSuperProfiles;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    selectProfile(profiles) {
      const formattedProfiles = profiles.filter((profileId) => {
        const superProfile = this.getSuperProfile(profileId);

        if (superProfile) {
          return superProfile.id !== undefined;
        }

        return false;
      });

      this.selectedProfiles = formattedProfiles;
    },
    reset() {
      this.selectedProfiles = [];
    },
    close() {
      this.dialog = false;
      this.reset();
      this.reject();
    },
    confirm() {
      this.resolve(this.selectedProfiles);
      this.reset();
      this.dialog = false;
    },
    removeSelectedPerson(index) {
      if (this.selectedProfiles[index]) {
        this.selectedProfiles.splice(index, 1);
      }
    },
  },
};

</script>

<style>
  .relatedProfilesTreeview .v-treeview-node__level {
    width: 0px;
    cursor: pointer;
  }
  .relatedProfilesTreeview .v-treeview-node--active {
    margin-bottom: 4px;
  }
  .chip-text {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
